import React from 'react'
import { Link as RouterLink } from 'gatsby'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Box, Text, Heading, Link, Space } from 'components'

import RichTextRenderer from 'components/RichTextRenderer'

const Author = ({
  author: {
    name,
    image,
    bio: { json: bio },
    email,
    linkedIn,
    facebook,
    twitter,
  },
  isCompact,
}) => {
  return (
    <Box
      sx={{
        borderTop: '1px solid',
        borderColor: 'colorBlue10',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Link
          as={RouterLink}
          to="/blog"
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 0,
            color: 'colorBlue50',
            textDecoration: 'none',
            justifyContent: 'center',
            width: ['100%', 'auto'],
            py: 3,
          }}
        >
          <Box
            as="svg"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            sx={{
              width: 12,
              height: 9,
              mr: 2,
            }}
          >
            <path
              d="M11.25 4.49984C11.25 4.84984 11.0167 5.08317 10.6667 5.08317H2.73334L5.24167 7.59151C5.475 7.82484 5.475 8.17484 5.24167 8.40817C5.125 8.52484 5.00834 8.58317 4.83334 8.58317C4.65834 8.58317 4.54167 8.52484 4.425 8.40817L0.925004 4.90817C0.866671 4.84984 0.808337 4.7915 0.808337 4.73317C0.750004 4.6165 0.750004 4.4415 0.808337 4.2665C0.866671 4.20817 0.866671 4.14984 0.925004 4.0915L4.425 0.591504C4.65834 0.358171 5.00834 0.358171 5.24167 0.591504C5.475 0.824837 5.475 1.17484 5.24167 1.40817L2.73334 3.9165H10.6667C11.0167 3.9165 11.25 4.14984 11.25 4.49984Z"
              fill="#8093AB"
            />
          </Box>
          zurück zum Blog
        </Link>

        {(facebook || linkedIn || twitter) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mx: -2,
              width: ['100%', 'auto'],
              py: 3,
            }}
          >
            {facebook && (
              <Link
                href={facebook}
                sx={{
                  display: 'block',
                  color: 'colorBlue50',
                  textDecoration: 'none',
                  px: 2,
                }}
              >
                <svg
                  width="11"
                  height="17"
                  viewBox="0 0 11 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.2207 4C10.2207 4.45 9.92157 4.75 9.47292 4.75H7.22969V6.25H9.47292C9.69724 6.25 9.92157 6.25 10.0711 6.475C10.2207 6.7 10.2954 6.925 10.2207 7.15L9.47292 10.15C9.39815 10.525 9.09905 10.75 8.72518 10.75H7.22969V16C7.22969 16.45 6.93059 16.75 6.48195 16.75H3.49097C3.04233 16.75 2.74323 16.45 2.74323 16V10.75H1.24774C0.799097 10.75 0.5 10.45 0.5 10V7C0.5 6.55 0.799097 6.25 1.24774 6.25H2.74323V4.75C2.74323 2.275 4.76214 0.25 7.22969 0.25H9.47292C9.92157 0.25 10.2207 0.55 10.2207 1V4Z"
                    fill="currentColor"
                  />
                </svg>
              </Link>
            )}

            {linkedIn && (
              <Link
                href={linkedIn}
                sx={{
                  display: 'block',
                  color: 'colorBlue50',
                  textDecoration: 'none',
                  px: 2,
                }}
              >
                <svg
                  width="18"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 0C1.725 0 0.75 0.975 0.75 2.25C0.75 3.525 1.725 4.5 3 4.5C4.275 4.5 5.25 3.525 5.25 2.25C5.25 0.975 4.275 0 3 0ZM6.75 9.75C6.75 6.825 9.075 4.5 12 4.5C14.925 4.5 17.25 6.825 17.25 9.75V15C17.25 15.45 16.95 15.75 16.5 15.75H13.5C13.05 15.75 12.75 15.45 12.75 15V9.75C12.75 9.3 12.45 9 12 9C11.55 9 11.25 9.3 11.25 9.75V15C11.25 15.45 10.95 15.75 10.5 15.75H7.5C7.05 15.75 6.75 15.45 6.75 15V9.75ZM1.5 5.25C1.05 5.25 0.75 5.55 0.75 6V15C0.75 15.45 1.05 15.75 1.5 15.75H4.5C4.95 15.75 5.25 15.45 5.25 15V6C5.25 5.55 4.95 5.25 4.5 5.25H1.5Z"
                    fill="currentColor"
                  />
                </svg>
              </Link>
            )}

            {twitter && (
              <Link
                href={twitter}
                sx={{
                  display: 'block',
                  color: 'colorBlue50',
                  textDecoration: 'none',
                  px: 2,
                }}
              >
                <svg
                  width="18"
                  height="15"
                  viewBox="0 0 18 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.8579 0.14161C17.0825 -0.00923687 17.4567 -0.00923687 17.6812 0.14161C17.9057 0.292456 18.0554 0.59415 17.9805 0.895843C17.756 1.95177 17.2321 2.93227 16.4837 3.76193V4.13904C16.4837 8.28733 14.6876 11.7568 11.4695 13.567C9.82304 14.5475 8.02689 15 6.1559 15C4.28492 15 2.26425 14.472 0.393269 13.4161C0.093911 13.2653 -0.0557678 12.8881 0.0190716 12.5865C0.0939111 12.2848 0.468108 12.0585 0.767466 12.0585C2.03974 12.0585 3.31201 11.8322 4.4346 11.3043C1.66554 9.56952 0.917145 7.15598 0.842305 5.27039C0.767466 3.08312 1.51586 1.27296 1.5907 1.19754C1.66554 0.971266 1.89006 0.744996 2.18941 0.744996C2.48877 0.744996 2.71329 0.820419 2.86297 1.04669C4.13524 2.85685 6.08106 3.9882 8.25141 4.13904C8.25141 2.93227 8.77528 1.80092 9.7482 1.04669C11.3198 -0.386353 13.6398 -0.31093 15.1366 1.04669C15.7353 0.820419 16.3341 0.518726 16.8579 0.14161Z"
                    fill="currentColor"
                  />
                </svg>
              </Link>
            )}
          </Box>
        )}

        {isCompact ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: ['100%', null, 'auto'],
              py: 4,
            }}
          >
            <Box variant="avatar.author" sx={{ width: 36, height: 36 }}>
              <Img alt={name} fluid={image.fluid} />
            </Box>

            <Heading.H6 sx={{ fontSize: [0, 0], ml: 3 }}>{name}</Heading.H6>

            {email && (
              <Link
                href={`mailto:${email}`}
                sx={{
                  display: 'block',
                  fontSize: 0,
                  color: 'colorBlue50',
                  textDecoration: 'none',
                  position: 'relative',
                  top: '1px',
                  ml: 2,
                }}
              >
                <Box
                  as="svg"
                  viewBox="0 0 13 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  sx={{
                    width: 13,
                    height: 12,
                    color: 'colorBlue50',
                  }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.1667 0.75H1.83337C0.841707 0.75 0.083374 1.50833 0.083374 2.5V9.5C0.083374 10.4917 0.841707 11.25 1.83337 11.25H11.1667C12.1584 11.25 12.9167 10.4917 12.9167 9.5V2.5C12.9167 1.50833 12.1584 0.75 11.1667 0.75ZM1.83337 1.91667H11.1667C11.4 1.91667 11.575 2.03333 11.6917 2.26667L6.50004 5.88333L1.30837 2.26667C1.42504 2.03333 1.60004 1.91667 1.83337 1.91667ZM1.25004 9.5C1.25004 9.85 1.48337 10.0833 1.83337 10.0833H11.1667C11.5167 10.0833 11.75 9.85 11.75 9.5V3.60833L6.85004 7.05C6.73337 7.10833 6.61671 7.16667 6.50004 7.16667C6.38337 7.16667 6.26671 7.10833 6.15004 7.05L1.25004 3.60833V9.5Z"
                    fill="currentColor"
                  />
                </Box>
              </Link>
            )}
          </Box>
        ) : (
          <Text
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 0,
              color: 'colorBlue50',
              textDecoration: 'none',
              width: ['100%', 'auto'],
              py: 3,
            }}
          >
            Über den/die AuthorIn
            <Box
              as="svg"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              sx={{
                width: 10,
                height: 11,
                ml: 2,
              }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.90829 7.075L5.40829 10.575C5.34996 10.6333 5.29163 10.6917 5.23329 10.6917C5.17496 10.75 5.05829 10.75 4.99996 10.75C4.94163 10.75 4.82496 10.75 4.76663 10.6917C4.70829 10.6333 4.64996 10.6333 4.59163 10.575L1.09163 7.075C0.858293 6.84167 0.858293 6.49167 1.09163 6.25833C1.32496 6.025 1.67496 6.025 1.90829 6.25833L4.41663 8.76667V0.833333C4.41663 0.483333 4.64996 0.25 4.99996 0.25C5.34996 0.25 5.58329 0.483333 5.58329 0.833333V8.76667L8.09163 6.25833C8.32496 6.025 8.67496 6.025 8.90829 6.25833C9.14163 6.49167 9.14163 6.84167 8.90829 7.075Z"
                fill="currentColor"
              />
            </Box>
          </Text>
        )}
      </Box>

      {!isCompact && (
        <Box sx={{ display: 'flex', pt: 3 }}>
          <Box sx={{ flexGrow: 1, pr: 6 }}>
            <Box>
              <Space mb={2}>
                <Heading.H5>{name}</Heading.H5>
                <Text>
                  <RichTextRenderer>{bio}</RichTextRenderer>
                </Text>
              </Space>
            </Box>
          </Box>

          <Box sx={{ flexShrink: 0 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Space mb={4}>
                <Box variant="avatar.author">
                  <Img alt={name} fluid={image.fluid} />
                </Box>

                {email && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      as="svg"
                      viewBox="0 0 13 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      sx={{
                        width: 13,
                        height: 12,
                        mr: 2,
                        color: 'colorBlue50',
                        position: 'relative',
                        top: '1px',
                      }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.1667 0.75H1.83337C0.841707 0.75 0.083374 1.50833 0.083374 2.5V9.5C0.083374 10.4917 0.841707 11.25 1.83337 11.25H11.1667C12.1584 11.25 12.9167 10.4917 12.9167 9.5V2.5C12.9167 1.50833 12.1584 0.75 11.1667 0.75ZM1.83337 1.91667H11.1667C11.4 1.91667 11.575 2.03333 11.6917 2.26667L6.50004 5.88333L1.30837 2.26667C1.42504 2.03333 1.60004 1.91667 1.83337 1.91667ZM1.25004 9.5C1.25004 9.85 1.48337 10.0833 1.83337 10.0833H11.1667C11.5167 10.0833 11.75 9.85 11.75 9.5V3.60833L6.85004 7.05C6.73337 7.10833 6.61671 7.16667 6.50004 7.16667C6.38337 7.16667 6.26671 7.10833 6.15004 7.05L1.25004 3.60833V9.5Z"
                        fill="currentColor"
                      />
                    </Box>

                    <Link
                      href={`mailto:${email}`}
                      sx={{
                        display: 'block',
                        fontSize: 0,
                        color: 'colorBlue50',
                        textDecoration: 'none',
                        pl: 4,
                        ml: -4,
                      }}
                    >
                      Kontakt
                    </Link>
                  </Box>
                )}
              </Space>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default Author

export const query = graphql`
  fragment Author on ContentfulAuthor {
    email
    facebook
    twitter
    linkedIn
    name
    image {
      fluid(maxWidth: 128) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    bio {
      json
    }
  }
`
