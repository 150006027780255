import React from 'react'

import { Box, Heading } from 'components'

import Article from './Article'

const RelatedArticles = ({ articles = [] }) => {
  if (!articles || articles?.length === 0) {
    return null
  }

  return (
    <Box>
      <Heading.H2 sx={{ textAlign: 'center' }}>
        Das könnte Sie auch interessieren
      </Heading.H2>

      <Box
        sx={{
          mt: 8,
          display: 'grid',
          gridTemplateColumns: ['100%', null, '50% 50%'],
          gap: 5,
        }}
      >
        {articles?.map((article) => (
          <Box key={article.id}>
            <Article {...article} />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default RelatedArticles
